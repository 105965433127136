.inner_mainbanner{
    margin: 50px 0px;
    position: relative;
}
.inner_mainbanner img{
    width: 100%;
    position: relative;
}
.groomingBanner{
    position: absolute;
    top: 0px;
    left: 50%;
    text-align: center;
    transform: translate(-50%, 20%);
}
.groomingBanner  p{
    color: #fff !important;
    width: 67% !important;
}
.avenue_pet a{
    display: flex;
    gap: 10px;
    align-items: start;
    width: 100%;
    text-decoration: none;
}
.grooming_storedetails img,
.avenue_pet img {
    width: 113px;
    height: 113px;
    border-radius: 10px;
    position: relative;
    margin-right: 0px;
}
.pet_avenue{
    position: relative;
}
.pet_avenue p{
    position: absolute;
    bottom: 0px;
    background: linear-gradient(0deg, rgba(225, 37, 27, 0.8), rgba(225, 37, 27, 0.8)), linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.25) 100%);
    background: linear-gradient(0deg, #E1251B, #E1251B), linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.2) 100%);
    color: #fff;
    padding: 3px 0px 3px;
    left: 0px;
    border-radius: 0px 0px 10px 10px;
    font-size: 14px;
    font-weight: 600;
    --common-font: "Poppins", sans-serif;
    width: 113px;
    margin: 0px;
    text-align: center;
}
.rightside_list .add_review{
    position: relative !important;
    margin: 0px;
}
.rightside_list .add_review span {
    color: #000;
}
.rightside_list{
    width: 657px;
}
.avenue_pet:hover{
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
    border: 1px solid #fff;
}
.avenue_pet :hover h1{
    color: rgba(225, 37, 27, 1);

}
.adopt_now:hover,
.avenue_pet:hover button{
    color: rgba(255, 241, 240, 1);
     background: rgba(225, 37, 27, 1);
}
.rightside_list #location{
    color: rgba(225, 37, 27, 1);
}
.avenue_pet button,
.adopt_now{
    position: absolute;
    bottom: 9px;
    background: rgba(255, 241, 240, 1);
    border: none;
    padding: 7px 25px;
    border-radius: 100px;
    color: rgba(225, 37, 27, 1);
}
.grooming_info .features_grooming .main_heading,
.grooming_info .features_grooming  .sub_heading{
text-align: left;
margin-left: auto;
width: auto;
}
.grooming_download{
    overflow: hidden;
}
#groomingdownload_info .dowanload_rightside{
    padding: 33px 36px 33px 56px;
    margin-right: 17px;
    width: 65%;
    position: relative;
}
#groomingdownload_info .app_downalod_Section .dowanload_img {
    width: 67%;
    margin: 14px 0px;
}
#groomingdownload_info  .footprint {
    width: 87px;
    transform: rotate(330deg);
    transform: translate(-66px, -78px);
}
.curve_rotation{

}
.leftside{
    width: 62px;
    transform: rotate(75deg);
}
.services_names{
    list-style: none;
    padding: 0px;
    margin: 0px;

}
.services_names li{
    display: flex;
    align-items: center;
    background-color: #fff;
    padding: 10px;
    margin: 14px;
    border-radius: 10px;
    height: 59px;
}
.services_names li:hover span,
.services_names .active span{
    background-color: #fff;
}
.services_names li:hover a,
.services_names .active a{
color: #fff;
}
.services_names li:hover,
.services_names .active{
    background: linear-gradient(45deg, rgb(141 24 18), rgb(225 37 27));

}
.services_names li a{
    font-family: var(--common-font);
    font-size: 16px;
    color: #000;
    text-decoration: none;
}
.accordion-body{
    padding: 0px;
}

.accordion-item{
    background: rgba(242, 242, 242, 1);
     margin-bottom: 15px;
     border-radius: 15px !important;
    
 }
#groomingdownload_info .left_img {
    height: 375px;
}
.accordion-header{
    font-family: var(--common-font);
    font-size: 16px;
    color: #000;
    text-decoration: none;
    border-radius: 15px;
    background:rgba(237, 237, 237, 1);
}
.accordion-header  button{
    border-radius: 15px !important;
    background: rgba(242, 242, 242, 1) !important;
}

.services_names li span{
    background: rgba(239, 239, 239, 1);
    padding: 10px 8px;
    border-radius: 50%;
    /* margin: -4px; */
    /* margin: 10px; */
    margin-right: 9px;
}

.services_names li span img{
    width: 25px;
    height: 25px;
}
.pet_info #location{
    background: rgb(233 236 238 / 0%);
    color: rgba(225, 37, 27, 1);
}
.locationvenue{
    font-weight: 600;
}
.pet_listing_innersection .col-md-4{
    padding: 0px 5px;
}
#groomingdownload_info .right_footerprint {
    width: 139px;
    transform: rotate(51deg);
    margin: 0px 0px 0px;
    position: absolute;
    top: 143px;
    /* height: 99px; */
    /* left: 0px; */
    right: 48px;
}
#groomingdownload_info .appdownloadlogo {
    background-color: #ffff;
    padding: 23px 20px;
    border-radius: 100px;
    transform: translate(-139px, -6px);
    position: relative;
}
#groomingdownload_info .footprint {
    width: 76px;
    transform: rotate(330deg);
    transform: translate(-150px, -31px);
}
#groomingdownload_info  .leftside {
    width: 143px;
    /* transform: rotate(75deg); */
    transform: translate(-146px, -3px);
}
.booked_services{
    display: flex;
    flex-direction: row;
}

.booked_services .location_creation a{
    display: flex;
    flex-direction: column;
    align-items: baseline;
}
.booked_services .grooming_board_cardbox img {
    height: 165px;
    position: relative;
    width: 167px;
}
.grooming_box img{
    width: 111px;
    height: 102px;
    border-radius: 10px;
    margin-right: 9px;
    object-fit: cover;
    position: relative;
}
.personal_details_petinfo h2,
.personal_details_petinfo h4{
    font-size: 18px;
    font-weight: 600;
    --common-font: "Poppins", sans-serif;
    margin: 6px 0px;
    text-transform: capitalize;
    color: #000;
}
.personal_details_petinfo p{
    font-size: 16px !important;
    font-weight: 400;
    --common-font: "Poppins", sans-serif;
    margin: 6px 0px;
    text-transform: capitalize;
    color: #000;
}
.booked_services  .grooming_board_cardbox{
    margin-right: 10px;
}
.pet_details_info{
    background: rgba(245, 245, 245, 1);
    padding: 12px;
    border-radius: 10px;
}
.date_time{
    padding: 0px;
    margin: 0px;
}
.pet_details_info p{
    font-size: 18px;
    font-family: "Poppins";
    font-weight: 400;
}
.pet_details_info .add_new_pet{
    font-size: 15px;
    color: #fff;
    --common-font: "Poppins", sans-serif;
    text-decoration: none;
    font-weight: 500;
    border: none;
    background: linear-gradient(45deg, rgb(141 24 18), rgb(225 37 27)) !important;
    padding: 5px 24px;
    border-radius: 100px;
    line-height: 18px;
    height: 40px;
}
.pet_details_info .grooming_board span{
    color: rgba(63, 63, 63, 1);
    font-weight: 800;
}
.more_info div h1{
    font-size: 21px;
    font-family: "Poppins";
    font-weight: 400;
    margin: 0px;
    font-weight: 600;
    text-transform: capitalize;
}
.more_info div{
    padding: 10px;
    background-color: #fff;
    border-radius: 10px;
}
.grooming_box{
    border: 1px solid #BDBDBD;
    padding: 10px;
    margin-top: 13px;
    border-radius: 10px;
    position: relative;
}
.date_time li span{}
.date_time li p{
    margin: 0px;
}
.pet_details_info h2{
    font-size: 18px;
    font-family: "Poppins";
    font-weight: 500;
    text-align: center;
}
.testnominal_img{
    width: 88px !important;
    height: 63px !important;
    border-radius: 10px;
    object-fit: cover;
}
.pet_slides{
    height: 186px;
}
.grooming_box .d-flex{
    justify-content: space-between !important;
}
.pet_name{
    font-size: 15px !important;
    text-align: left !important;
}
.location_view{
    margin: 0px;
    font-size: 13px !important;
}
 .review_pet  svg {
    font-size: 15px !important;
}
.location_icon{
    background: #E0E0E0;
    padding: 5px 10px 11px;
    border-radius: 9px;
    margin-right: 7px;
}
.pet_details_info .add_review{
    position: relative;
}
.pet_details_info span{
color: #000 !important;
font-size: 14px;
}
#booking_info{
    background-color: #fff;
}
.date_time li{
    background-color: #fff;
    display: flex;
    margin-bottom: 10px;
    border-radius: 10px;
    padding: 10px 11px;
    justify-content: space-between;
}
.more_info ul{
    padding: 0px;
    margin: 0px;
    display: flex;
    list-style: none;
    flex-wrap: wrap;
    align-items: center;
}
.more_info ul li{
    display: flex;
    align-items: center;
    margin: 11px 0px 0px 7px;
}
.more_info ul li p{
    margin: 0px;
    margin-left: 10px;
    font-size: 15px;
}
.more_info ul li span{
    background: #E0E0E0;
    padding: 5px 12px 11px;
    border-radius: 100px;
}

.pet_details_info{
    margin-top: 14px;
    margin-right: 7px;
}
.add_new_info{
    display: flex;
    justify-content: space-between;
}
.pet_img{
    width: 103px;
    height: 103px;
    border-radius: 10px;
    object-fit: cover;
}

.pet_details_name{
    text-align: left !important;
    margin: 0px !important;
}
.pet_info span{
color: #a39898 !important;
}
.pet_info{
    text-align: left  !important;
    font-family: "Segoe UI"  !important;
    font-size: 17px !important;
    font-weight: 400  !important;
    line-height: 40px  !important;
    margin: 0px  !important;
    color: #000;
}
.pick_drop_info{
    margin: 0px;
    background-color: #fff;
    font-size: 16px !important;
    border-radius: 10px;
    padding: 0px 10px;
    width: 204px;
}

.pick_drop_info .pet_info{
    display: flex;
    justify-content: space-between;
}

.address_details{
    font-size: 15px !important;
    line-height: 24px !important;
}
.list-unstyled{
margin-bottom: 0px;
}
.services_names li:hover{
    color: #fff !important;
}
.features_grooming  .main_heading{
    font-size: 25px !important;
   
}
.grooming_storedetails .grooming_innerdetails{
    display: flex;
    flex-direction: row;
}
.grooming-btns{
    display: flex;
    justify-content: space-between;
    
}
.grooming_storedetails .adopt_now,
.grooming_storedetails  .booknow_service{
    transition: 0.3s;
    background: rgba(225, 37, 27, 1);
    background: linear-gradient(45deg, rgb(141 24 18), rgb(225 37 27));
    padding: 7px 22px;
    border-radius: 100px;
    border: none;
    color: #fff;
    font-size: 14px;
}
.grooming_storedetails .connect_btn .adopt_now{
    transition: 0.3s;
    background: rgba(255, 241, 240, 1);
    color: rgba(225, 37, 27, 1) !important;
    padding: 8px 25px;
    border-radius: 100px;
    border: none;
    
}
.grooming_storedetails .connect_btn .adopt_now a{ 
    color: rgba(225, 37, 27, 1) !important;
}
.connect_btn:nth-child(2) .adopt_now{
    margin-left: 10px;
}

.grooming_innerdetails .rightside_list{
    margin-left: 17px;
    height: auto;
    width: 92% !important;
    height: 168px;
    object-fit: cover;
}
.grooming_innerdetails .grooming_storeimg{
    width: 100% !important;
    height: 170px;
    object-fit: cover;

}
.grooming_innerdetails p{
    text-align: left !important;
    margin-bottom: 0px;
    font-size: 16px  !important;
    line-height: 24px  !important;
}
strike{
    color: rgba(225, 37, 27, 1);
}
.booknow  .modal-dialog{
    max-width: 462px;
}
.booknow .sub_heading {
    width: 78%;
    margin: auto;
}
.documnet{
    font-family: var(--common-font);
      font-size: 22px;
      line-height: 28px;
      text-align: center;
    color: #263238;
  font-weight: 500;
  }
  .document_show{
    font-weight: 400;
    font-family: var(--common-font);
    font-size: 16px;
    line-height: 11px;
    text-align: center;
    color: #263238;
    margin-bottom: 31px;
  }
  .adopt_dogdetails .add_review,
  .adoptionstore_details .add_review {
    position: relative;
    bottom: 0px;
}

.slick-prev:hover, .slick-prev:focus, .slick-next:hover, .slick-next:focus {
    color: #000000;
    outline: none;
    background: #fff;
}
.pet_slidercontainer .slick-prev {
    left: 4px ! important;
}
.pet_slidercontainer  .slick-next {
    right: 0px  ! important;
}