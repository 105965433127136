@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
/**/
@font-face {
  font-family: Segoe UI;
  src: url("../../font/Segoe\ UI.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
:root {
  --header-background-color: #101820;
  --common-background: #fff;
  --common-font: "Poppins", sans-serif;
}

.frisbee_login_main_section {
  border-radius: 20px;
}
/* .frisbee_login_main_section div{
    padding-right: 0 !important;
} */
.frisbee_main_section_div img {
  padding: 1px 20px 0px 20px;
  border-radius: 85px 85px 35px 35px;
}
.frisbee_main_section_div {
  border-radius: 20px;
  background: linear-gradient(rgba(0, 0, 0, 0.35), rgba(0, 0, 0, 0)), #c73633;
}
.frisbee_main_section_div_child {
  display: flex;
  flex-direction: column;
  text-align: center;
  margin-top: 30px;
  margin-bottom: 17px;
}
.frisbee_main_section_div_child span {
  font-family: "Poppins";
  font-weight: 500;
  font-size: 25px;
  line-height: 25px;
}
.frisbee_main_section_div_child .login_professional_servies {
  font-family: "Poppins";
  font-weight: 600;
  font-size: 25px !important;
  line-height: 40px;
}
.frisbee_login_right_section_main {
  align-items: center;
}
.frisbee_login_right_section_main h4 {
  font-family: "Poppins";
  font-size: 20px;
  font-weight: 600;
  line-height: 33px;
}
.frisbee_login_right_section_main .login_quic_affordable {
  border-top: 1px solid #ececec;
  border-bottom: 1px solid #ececec;
  padding: 6px;
  width: 100%;
  font-size: 18px;
  font-family: "Segoe UI";
}
.frisbee_login_right_section_main form {
  width: 100%;
  margin-top: 18px;
}
.login_right_side_form label {
  font-size: 18px;
  font-family: "Poppins";
  font-weight: 500;
}
.login_user_for_left_section {
  /* padding: 0.7rem !important; */
  padding: 0px 14px 0px !important;
}
/* .login_right_side_btn_section button {

  padding: 4px 47px;
} */
.login_right_side_btn_section button:hover {
  border-color: #e3e3e4;
}
.login_right_side_btn_section button:active {
  border-color: #e3e3e4 !important;
  background-color: #fff !important;
  color: #000 !important;
}
.login_term_And_condation_bottom {
  font-family: "Segoe UI";
  font-weight: 400;
  font-size: 16px;
  color: rgba(81, 81, 81, 1) !important;
}
.login_term_And_condation_bottom a {
  text-decoration: none;
  color: red !important;
  font-weight: 600;
  font-size: 16px;
}
.login_right_side_btn_section {
  gap: 10px;
}
.login_right_side_btn_section button {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  gap: 10px;
  border: 1px solid #e3e3e4;
  color: black;
}
.login_for_hr hr {
  color: #939393 !important;
}
.login_input_for_mobile_no {
  border: 1px solid #ececec;
  border-radius: 25px;
  /* overflow: hidden; */
}
.login_input_for_mobile_no div button {
  border: none;
  color: unset;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 3px;
  padding: 5px 11px 5px 17px !important;
}
.login_input_for_mobile_no div button:active {
  color: unset !important;
  background-color: unset !important;
  border-color: unset !important;
}
.login_input_for_mobile_no input {
  border: none;
  border-radius: 35px;
}
.login_input_for_mobile_no input:focus {
  border: none !important;
  box-shadow: none !important;
}
.login_input_for_mobile_no {
  align-items: center;
}
.login_straight_line {
  color: #e8e0e0 !important;
}
.login_main_dropdown_class a {
  display: flex;
  justify-content: flex-start;
  gap: 5px;
  padding: 2px 17px;
}
.login_main_dropdown_class a span {
  font-size: 13px;
  font-family: "Poppins";
}
/* //User login otp section */
.login_user_otp_for_paragraph {
  width: 86%;
  top: 10px !important;
  left: 55% !important;
}
.login_user_otp_for_paragraph p {
  font-family: "Poppins";
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 10px;
  margin: 0px 11px;
}
.login_user_otp_for_paragraph h2 {
  font-family: "Poppins";
  font-size: 20px;
  font-weight: 700;
}
.login_user_right_side_main_section {
  text-align: center;
  align-items: center;
  display: flex;
}
.login_user_otp_for_bottom_button {
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
}
.login_user_right_side_main_section div h3 {
  font-family: "Open Sans", sans-serif;
  font-size: 22px;
  font-weight: 600;
}
.login_user_right_side_main_section div p {
  font-family: "Segoe UI";
  font-size: 16px;
  font-weight: 300;
  line-height: 30px;
}
.store_input_field_field:focus {
  box-shadow: none;
  border: 1px solid black;
}
.login_user_right_side_main_section div span {
  font-family: "Open Sans", sans-serif;
  font-size: 22px;
  font-weight: 600;
}
.login_user_enter_otp input {
  font-size: 18px;
  width: 36px !important;
}
.login_user_otp_for_bottom_button .user_otp_first {
  font-family: "Poppins";
  font-weight: 500;
  font-size: 16px;
  background-color: #e1251b !important;
  color: #fff;
  border: none;
  background: linear-gradient(
    90deg,
    rgba(0, 0, 0, 0) 32.52%,
    rgba(0, 0, 0, 0.2) 100%
  );
}
.login_user_otp_for_bottom_button .user_otp_first:hover {
  border: none;
  color: #fff;
}
.login_user_otp_for_bottom_button .user_otp_second {
  background: #ffebea;
  color: red;
  border: none;
  font-family: "Poppins";
  font-weight: 500;
  font-size: 16px;
}
.login_user_otp_for_bottom_button .user_otp_second:hover {
  background: #ffebea;
  color: red;
  border: none;
}
.login_user_otp_for_bottom_button .user_otp_second:focus-visible {
  border: none;
  box-shadow: none;
  outline: none;
}
.user_login_top_right_cross_btn {
  background: #fff1f0;
  right: -6px;
  top: -6px;
  border-radius: 50%;
  padding: 5px;
  width: 40px;
  height: 41px;
}
.user_login_top_right_cross_btn button {
  border: navajowhite;
  background: transparent;
  color: red;
  font-size: 22px;
  margin: -5px 3px 3px 4px !important;
}
.login_popup_modal_body {
  padding-bottom: 0 !important;
  padding-top: 0 !important;
  padding-left: 13px;
}
/* /// */

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.login_sent_button {
  position: absolute;
  right: 1px;
  background-color: #e1251b !important;
  padding: 7px 8px;
  border-radius: 17px;
  top: 1px;
  border: none;
  background: linear-gradient(
    90deg,
    rgba(0, 0, 0, 0) 32.52%,
    rgba(0, 0, 0, 0.2) 100%
  );
  border-top-left-radius: 17px !important;
  border-bottom-left-radius: 17px !important;
}
.login_sent_button img{
display: flex;
}