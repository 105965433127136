/**/
.main_heading {
    font-family: var(--common-font);
    font-size: 31px;
    text-align: center;
    line-height: 28px;
    text-transform: capitalize;
    margin: 0px;

}
.main_services span ,
.enquiery_form h2,
.features_grooming span{
    color: rgba(225, 37, 27, 1);
}
.sub_heading{
    width: 60%;
    margin: auto;
}

.sub_heading,.services p,
.grooming_innerdetails p,
.inner_sec,
.grooming_board .adoptionstore_details p,
.location_creation p {
    font-family: Segoe UI;
    font-size: 17px;
    text-align: center;
    line-height: 28px;
 
}
.main_services .slide-item img{
    margin-bottom: 8px;
    background-color: #fff;
    padding: 12px;
    border-radius: 100px;
    width: 61px;
}
.main_services  .services{
    margin: 25px 9px;
}
.main_slider {
    margin: 35px 0px;
}

.slick-prev:before {
    content: '';
    background-image: url("../../public/frisbeeImage/left_icon.svg");
    background-size: contain; 
    background-repeat: no-repeat;
    display: block;
    width: 17px;
    height: 17px;
    transform: translate(-4px, -9px);
}

.slick-next:before {
    content: '';
    background-image: url('../../public/frisbeeImage/right_icon.svg');
    background-size: contain;
    background-repeat: no-repeat;
    display: block;
    width: 17px;
    height: 17px;
    transform: translate(-4px, -9px);
}
.slick-next {
    right: -13px;
    display: block;
    padding: 18px;
    box-shadow: 0px 4px 14px 0px rgb(0 0 0 / 11%);
    background-color: #fff;
    border-radius: 30px;
}
.slick-prev {
    left: -20px;
    display: block;
    padding: 18px;
    box-shadow: 0px 4px 14px 0px rgb(0 0 0 / 11%);
    background-color: #fff;
    border-radius: 30px;
    z-index: 99999;
}
.main_services .slide-item {
    text-align: center;
    display: flex !important;
    flex-direction: column !important;
    align-items: center !important;
    padding: 16px;
    background: rgba(245, 245, 245, 1);
    border-radius: 10px;
    height: 128px;

}
.main_services .slide-item:hover p,
.view_all:hover a{
color:#fff;
transition: 0.3s;
}
.main_services .slide-item:hover ,
.view_all:hover{
    transition: 0.3s;
    background:  rgba(225, 37, 27, 1);
    background: linear-gradient(45deg, rgb(141 24 18), rgb(225 37 27));
}
.main_services .slick-slide{
    padding: 14px 7px;
    text-align: center;
}
#featured_grooming .main_heading ,
.location_creation a p,
#featured_grooming  .sub_heading{
    text-align: left;
margin-left: 0px;
width: auto;
}
.view_all{
    border-radius: 100px;
    border: 1px solid #80808042;
    background-color: #fff;
    padding: 7px 21px;
    line-height: 25px;
    text-align: center;
    /* margin: 11px 0px; */
}
.view_all a{
    font-size: 15px;
    color: rgba(0, 0, 0, 1);
    --common-font: "Poppins", sans-serif;
    text-decoration: none;
    font-weight: 500;
}
.view_btn{
    text-align: end;
    position: relative;
}
/*grooming_board slider*/
.grooming_board_cardbox{
   
}
.avenue_pet,
.grooming_storedetails,
.grooming_board{
    margin: 10px 5px;
    padding: 10px;
    border-radius: 10px;
    border: 1px solid #80808042;
    background-color: #fff;
    position: relative;
}
.grooming_board_cardbox{
    position: relative; 
}
.add_review{
    position: absolute;
    bottom: 0px;
}
.add_review ul{
    padding: 0px;
    list-style: none;
    bottom: 0px;
    display: flex;
    margin: 0px;
}
.image-gallery-description ,
.offer_discount{

    position: absolute;
    right: 10px;
    background: linear-gradient(45deg, rgb(141 24 18), rgb(225 37 27)) !important;
    top: -10px;
    padding: 6px 12px 14px;
    border-radius: 0px 0px 50px 50px;
    z-index: 9;

}
.image-gallery-description,
.offer_discount h1{
    color: #fff;
    --common-font: "Poppins", sans-serif;
    font-size: 21px;
    font-weight: 700;
    margin: 0px;
}
.image-gallery-description,
.offer_discount p,
.add_review span{
    color: #fff;
    --common-font: "Poppins", sans-serif;
    font-size: 17px;
    margin: 0px;
    text-align: center;
    text-transform: uppercase;
    font-weight: 400;
    line-height: 17px;
}
.add_review span{
    font-weight: 600 !important;
    margin-left: 10px;
}
.grooming_board_cardbox img{
    border-radius: 10px;
    width: 100%;
    height: 270px;
    object-fit: cover;
    object-position: top;
}
.add_review{
    display: flex;
    margin: 12px 10px;
    align-items: center;
}
.grooming_innerdetails h1,
.description_details,
.slider_testinominal h1,
.grooming_board_productcard h1,
.galler_heading,
.adoption_info h1,
.avenue_pet a h1,
.location_heading{
    font-size: 18px;
    font-weight: 600;
    --common-font: "Poppins", sans-serif;
    margin: 6px 0px;
    text-transform: capitalize;
    color: #000;
}
.location_creation a{
    display: flex;
    align-items: center;
    text-decoration: none;
}
.location_creation a svg{
    background: rgba(233, 236, 238, 1);
    padding: 7px 7px;
    width: 35px;
    color: #000;
    height: 31px;
    border-radius: 7px;
    margin-right: 9px;
}
.add_review ul li svg{
    color: #FFA726;
    font-size: 18px;
    margin: 0px 2px;
}
.adoption_info p{
    margin-left: 0px;
}

.adoption_info p,
.adoption_info ul li ,
.avenue_pet p,
.pet_details,
.slider_testinominal p,
.location_creation a p{
    font-family: Segoe UI;
    margin: 0px;
    line-height: 26px;
    font-size: 16px;
  
    color: rgba(63, 63, 63, 1);

}
.enquiery_form{
    background: rgba(208, 237, 255, 1);
    border-radius: 150px 0px 0px 150px;
    position: absolute;
    right: 0px;
    width: 493px;
    text-align: center;
    height: 279px;
    padding: 52px 53px;
}
.enquiery{
    display: flex;
}
.enquiery img{
    width: 100%;
}

.enquiery{
    background-image: url(../../public/frisbeeImage/enquiery_banner.png);
    background-repeat: no-repeat;
    position: relative;
    background-size: contain;
    height: 279px;
    border-radius: 10px;
    background-position: left;
}
.enquiery_form h2{
    text-align: center;
}
.enquiery_form h1{
    font-size: 22px;
    text-align: center !important;
    line-height: 33px;
}
.enquiery_form h2{
    text-align: center !important;
    font-size: 26px;
}
.enquiery_logo{
    position: absolute;
    width: 100px !important;
    left: -56px;
    background-color: #fff;
    padding: 13px;
    border-radius: 100px;
    box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.1);
    top: 72px;
}
.enquiery_form button:hover{
  color: rgba(225, 37, 27, 1);
  background: #fff;
}
.groomingBanner button,
.enquiery_form button,
.adopt_banner button{
    font-weight: 500;
    background: rgba(225, 37, 27, 1);
    color: #fff;
    font-size: 16px;
    --common-font: "Poppins", sans-serif;
    border: 3px solid #fff;
    border-radius: 100px;
    padding: 9px 22px;
    line-height: 23px;
    margin: 10px 0px;
}
.adopt_banner img{
width: 100%;
position: relative;
}
.adopt_banner{
    position: relative;
    margin-bottom: 12px;
}
.adoptbanner_innnercontent{
    position: absolute;
    top: 0px;
    width: 500px;
    text-align: center;
    left: 34%;
    padding: 27px 0px;
}
.adoptbanner_innnercontent h1{
    font-size: 29px;
    font-weight: 600;
    --common-font: "Poppins", sans-serif;
    margin: 6px 0px;
    text-transform: capitalize;
    color: #fff;
}
.groomingBanner button,
.adopt_banner button {
    background: rgb(46 63 81);
    font-weight: 500;
    margin: 22px 0px;
}
.groomingBanner button:hover,
.adopt_banner button:hover {
    background: #fff;
    color: rgb(46 63 81);
}
.adopt_banner button:hover a{
    color: rgb(46 63 81);
}
.app_downalod_Section{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.app_downalod_Section .dowanload_rightside{
    display: flex;
    align-items: center;
    background-color: aquamarine;
    background: linear-gradient(0deg, #304254, #304254), linear-gradient(101.62deg, rgba(0, 0, 0, 0) 23.55%, rgba(0, 0, 0, 0.2) 93.36%);
    border-radius: 0px 50px 50px 0px;
    padding: 33px 10px;
    margin-right: 17px;

}
.groomingBanner h1,
.dowanload_rightside h1{
    font-family: Miss Magnolia;
    color: #fff;
    font-weight: 500;
    font-style: normal;
    text-transform: uppercase;
    font-size: 55px;
    line-height: 40px;
    margin: 0px;
}
.dowanloadapp_section{
    margin-left: 20px;
    width: 442px;
}
.dowanload_rightside p{
    font-size: 22px;
    font-weight: 600;
    --common-font: "Poppins", sans-serif;
    margin: 0px 0px;
    text-transform: capitalize;
    color: #ffff;
}
.right_footerprint {
    width: 142px;
    transform: rotate(51deg);
    margin: 19px 0px 0px;
}
.app_dowanlad{
    overflow:hidden
}

.footprint {
    width: 105px;
    transform: rotate(330deg);
}
.appdownloadlogo {
    background-color: #ffff;
    padding: 29px 28px;
    border-radius: 100px;
}

.app_downalod_Section .dowanload_img{
    width: 65%;
    margin: 14px 0px;
}
.left_img{
    height: 308px;
}
.grooming_board span ,
.what_see span,
.slider_testinominal span,
.contact_btn{
    color: rgba(225, 37, 27, 1);
}
.logo_frishbee {
    background-color: #fff;
    border-radius: 100px;
    width: 100px;
    padding: 14px;
    margin-right: 36px;
    text-align: center;
    height: 100px;
}
.contactstripe{
    background: linear-gradient(0deg, #F7EAE9, #F7EAE9),
    linear-gradient(90deg, rgba(101, 34, 31, 0) 0%, rgba(101, 34, 31, 0) 100%);
    padding: 17px;
    border-radius: 14px;
}
.text-align-right{
    text-align: end;
}
.logo_frishbee img{
    width: 57px;
    margin: 7px;
}
.contactstripe p{
    font-family: Segoe UI;
    margin: 0px;
    font-size: 20px;
    font-weight: 600;
    line-height: 16px;
    margin: 17px 0px 0px;
}
.contactstripe h1{
    color: rgba(225, 37, 27, 1);
    font-size: 38px;
    font-weight: 600;
    --common-font: "Poppins", sans-serif;
    margin: 0px 0px;
    text-transform: capitalize;
}
.logo_img{
    display: flex;
}
.contact_btn:hover{
     background: rgba(225, 37, 27, 1);
     color: rgba(255, 255, 255, 1);
}
.contact_btn{
    background: rgba(255, 255, 255, 1);
    border: none;
    padding: 10px 28px;
    font-weight: 500;
    border-radius: 100px;
    transform: translate(10px, 25px);
}
.slider_testinominal .testimonial_img_pet{
    width: 147px;
    height: 189px;
    object-fit: cover;
    object-position: top;
    margin-right: 10px;
    border-radius: 0px 10px 10px 0px;
    position: relative;
    border: 4px solid #fff;
    box-shadow: 7px 0px 8px 0px rgba(0, 0, 0, 0.09);
}
.slider_testinominal{
    box-shadow: 0px 0px 14px 4px rgba(0, 0, 0, 0.1);
    display: flex;
    padding: 19px 5px 19px 0px;
    margin: 10px;
    align-items: start;
    border-radius: 10px;
}
.slider_testinominal:nth-child(1) div{
    position: relative;
}
.slider_testinominal .pet_photo{
    position: absolute;
    bottom: 0px;
    background-color: antiquewhite;
    background: linear-gradient(45deg, rgb(141 24 18), rgb(225 37 27));
    color: #fff;
    padding: 2px 10px;
    left: 13px;
    border-radius: 10px 10px 0px 0px;
    font-size: 16px;
}
.slider_testinominal .add_review {
    position: unset;
    margin: 0px 10px;
}
.slider_testinominal  .add_review span {
    color: red;
}
.slick-active .slider_testinominal{
    margin: 60px 10px;
    opacity: 70%;
}
.slick-center .slider_testinominal{
    margin: 10px;
    opacity: 100%;
}
.location_name{
    text-transform: capitalize;
    margin: 0px !important;
}
.what_see .slick-prev{
    left: 46% !important;
    top: 80%;
    transform: translate(0, 0%);
}
.what_see .slick-next {
    right: 46% !important;
    top: 80%;
    transform: translate(0, 0%);
}
.what_see{
    margin: 46px 0px 0px;
}
.what_see .slide-item.inactive {
    opacity: 0.5; 
    filter: grayscale(100%);
}
.what_see .slick-center .slide-item.inactive {
    opacity: 100% !important;
    filter: grayscale(0%) !important;
}
 
.slick-slide {
    position: relative;
}

.main_slidercontent{
    position: absolute;
    bottom: 0px;
}
.main_slidercontent p{
    font-family: Miss Magnolia;
    font-weight: 500;
    font-size: 56px;
    line-height: 40px;
    margin: 0px;
    color: #fff;
    position: absolute;
    top: -226px;
    left: 138px;
    display: flex;
}
.main_slidercontent h1{
    font-family: Miss Magnolia;
    font-weight: 500;
    font-size: 118px;
    font-style: normal;
    text-transform: uppercase;
    line-height: 40px;
    margin: 0px;
    color: rgba(225, 37, 27, 1);
    position: absolute;
    top: -153px;
    display: flex;
    width: 100%;
    left: 128px;
    z-index: 9999;
}
.left_side{
    width: 56px;
    /* transform: rotate(199deg); */
    position: absolute;
    top: -192px;
    left: 65px;
}
.your{
    margin: 19px;
}
.happy{
    margin: 32px 11px;
}
#language_drop .drop_down {
    min-width: 150px;
}
.adopt_banner button a{
    text-decoration: none;
    color: #fff;
}
.shop_info{
    font-weight: 700;
    color: #000;
    font-size: 15px;
    --common-font: "Poppins", sans-serif;
    margin: 0px;
}
.dots{
    color: gray;
}
.animation_text{
    color: #fff !important;
    z-index: 99 !important;
    left: 134px !important;
    font-size: 117px !important;
    top: -153px !important;
}
.product_size,
.product_price{
    display: flex;
}
.product_size .gram_productsize{
    background: rgba(255, 241, 240, 1);
    color: rgba(225, 37, 27, 1);
    padding: 6px 14px;
    border-radius: 100px;
}
.product_size .kilo_productsize{
    background: rgba(243, 243, 243, 1);
    padding: 6px 14px;
    border-radius: 100px;
    font-family: Segoe UI;
    margin-left: 9px;
}
#clothing_page .col-md-4{
    padding-right: 0px;
}
.grooming_board_productcard button:hover{
    color: rgba(255, 241, 240, 1) !important;
    background:  #e1251b !important;

}
.grooming_board_productcard button{
    color: #e1251b !important;
    background: rgba(255, 241, 240, 1) !important;

}
.main_slider  .slidermain_img{
    width:100%;
    height: 451px;
    object-fit: cover;
    border-radius: 10px;
} 
#cartpop{
    margin-right: 0px;
    transform: translate(0, 42px) !important;
    border-radius: 0px !important;
}
#cartpop .modal-content{
    border-radius: 0px !important;
}

.fade{
    z-index: 999999999;
}
.cart_footer{
    position: fixed;
    background-color: #fff;
    width: 100%;
    bottom: 0px;
    padding: 16px;
}
.pop-header{
    background: rgba(255, 241, 240, 1);
    text-align: center;
    padding: 20px 0px;
    border-radius: 10px;
}
.pop-header p{
    font-family: 'Poppins';
    font-size: 16px;
    text-align: center;
    line-height: 28px;
    padding: 6px 39px;
    text-transform: capitalize;
    margin: 0px;
}
.subtotal{
    display: flex;
    justify-content: space-between;
    background: rgba(239, 239, 239, 1);
    padding: 16px;
    border-radius: 10px;
}
.product_item{
    display: flex;
    align-items: start;
    border-bottom: 2px solid #E0E0E0;
    padding: 14px 0px;
}
#cartpop .btn-close{
    position: absolute;
    right: 5px;
    top: 12px;
}
.cart_view img{
    width: 76px;
    height: 100px;
}
.item_info{
    width: 100%;
}
.item_info h1{
    font-family: 'Poppins';
    font-size: 17px;
    text-align: left;
    line-height: 28px;
    font-weight: 500;
}
.cart_view{
    margin: 6px 19px 6px 0px;
    background: rgba(244, 244, 244, 1);
    border-radius: 10px;
    padding: 20px;
}
#product_item  .character_stics{
    margin-right: 0px !important;
}
#product_item{
    overflow: scroll;
    height: 400px;

}
.item_info .add_review {
    position: relative;
    bottom: 0px;
    margin: 0px;
}
.character_stics p{
    font-family: 'Poppins';
    font-size: 16px;
    text-align: center;
    line-height: 28px;
    font-weight: 600;
    margin: 0px;
}
.product_price{
    justify-content: space-between;
}
.cart_plus{
    display: flex;
    align-items: center; 
}
.checkout,
.sign_up,
.cart_min{
    background: linear-gradient(45deg, rgb(141 24 18), rgb(225 37 27)) !important;
    color: #fff;
    border: none;
    border-radius: 100px;
    line-height: 1px;
    padding: 6px;
}
.view_cart,
.sign_up,
.checkout{
    padding: 20px 32px !important;
}
.checkout a{
    color: #fff;
}
.checkout:hover{
    background-color: #fff;
}
.checkout:hover a{
    color: rgba(225, 37, 27, 1);
}
.view_cart,
.cartplus{
    background: rgb(84 84 84 / 19%);
    border: none;
    border-radius: 100px;
    line-height: 1px;
    padding: 6px;
    color: #000;
}
.cart-item{
    margin: 0px 14px;
}
.subtotal p{
    font-family: 'Poppins';
    font-size: 17px;
    text-align: left;
    line-height: 28px;
    font-weight: 500;
    margin: 0px;
}
.subtotal h1{
    font-family: 'Poppins';
    font-size: 17px;
    text-align: right;
    line-height: 28px;
    font-weight: 500;
    margin: 0px;
    text-transform: uppercase;
}
.view_cart a{
    color:#000
}
.cartfooter{
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
}
.select_dete_time{
    display: flex;
    flex-direction: column;
    margin: 0px 35px;
}
.select_dete_time input{
    padding: 10px 21px;
    border-radius: 100px;
    color: #000;
    border: 1px solid rgb(177 177 177 / 40%);
    margin-bottom: 15px;
}
.select_dete_time select{
    padding: 10px 21px;
    border-radius: 100px;
    color: #000;
    border: 1px solid rgb(177 177 177 / 40%);
    margin-bottom: 15px;

}
#product-inner .dogdetails_info li h1{
    font-size: 15px !important;
}
.modelheader h1{
    font-size: 25px !important;
}
.modelheader span{
    color: rgba(225, 37, 27, 1);
}
#product-inner{
    height: 500px;
    overflow-y: hidden;
}
#product-inner:hover{
    overflow-y: auto;
}
#product-inner p{
    font-family: Segoe UI;
    font-size: 17px;
    line-height: 28px;
}
 .drop_downsidebar{
    margin-left: 90px;
    font-size: 25px;
}
.main_slider .container-fluid{
    padding-right: 0px;
    overflow: hidden;
}
.padding-right{
    padding-right:0px !important;
}